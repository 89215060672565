import { Link } from "react-router-dom";

const NewspaperSection = ({ id, title, image, text, color, subtitle }) => {

  const isColorDark = (color) => {
    const hex = color.replace(/^#/, '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminance < 0.5;
  };

  const textColor = isColorDark(color) ? 'black' : 'white';


  const getFirstParagraph = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const firstParagraph = tempDiv.querySelector('p');

    return firstParagraph ? firstParagraph.innerHTML : tempDiv.innerHTML;
  };


  return (
    <Link to={`/noticias/${id}`}>
      <div className="newspaper-section" style={{ backgroundColor: 'transparent' }}>
        <h1 className="title" style={{ color: 'black' }}>{title}</h1>
        <h2 className="subtitle" style={{ color: 'black' }}>Escrito por: <span style={{ color: 'black' }}>{subtitle}</span></h2>
        <div className="content">
          <img src={image} alt="newspaper" className="image" />
          <div dangerouslySetInnerHTML={{
            __html: getFirstParagraph(text)
          }} className="text" style={{ color: 'black' }}></div>
        </div>
        <div className="columns-container">
          <Link to={`/noticias/${id}`} target='_blank'>
            <div className='button_categorias' style={{ backgroundColor: color, color: textColor }}>
              Ver más
            </div>
          </Link>
        </div>
      </div>
    </Link>

  );
};

export default NewspaperSection;